import * as React from "react";
import "./devis.css";
import Layout from "../layouts/layout";




const DevisPage = () => {


    return (
        <>
            <a href="https://annuaire.secous.com/chirurgie-c435-p1.html">annuaire gratuit - Chirurgie</a>
            <a href="https://annuaire.secous.com/">annuaire secous</a>
            <a  href="https://www.gralon.net/annuaire/sante-et-beaute/medecine/chirurgie-esthetique.htm ">gralon</a>
            <a href="https://www.koala-annuaireweb.com/sante-bien-etre/chirurgie-esthetique/">Koala Surfer - Annuaire référencement gratuit - Chirurgie esthétique</a>
            <a href="https://www.koala-annuaireweb.com">Koala Surfer - Annuaire référencement gratuit</a>
            <a href="https://www.actimonde.com/" title="Annuaire web gratuit sites de qualité">Actimonde - Annuaire web gratuit</a>

            <a href="https://annuaire-tourisme.danslemonde.net/gestion/in.php?url_id=3524" target="_blank"><img border="0" src="https://annuaire-tourisme.danslemonde.net/logo.gif" width="468" height="60"/></a>       
            
             </>
   
   )
}

export default DevisPage
